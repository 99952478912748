$theme: (
  background-light: #466AE0,
  background-dark: #16133E,
  divider-dark: #466AE0,
  shadow:  0 1px 18px 0 rgba(0,0,0,0.03),
  transition: (
    default: 0.15s all ease-in-out,
    input: 0.15s border ease-in-out
  ),
  primary: #16133E,
  success: #27d675,
  warning: #f3e24b,
  error: #f70000,
  border: (
    input-color: #E5EAF4,
    input: #E5EAF4,
    input-focused: #183B56,
  ),
  text: (
    primary: #16133E,
    primary-light: #FFFFFF,
    secondary1: #5A7184,
    secondary2: #87A1B6,
    secondary3: #183B56,
    error: #f70000
  )
);

$breakpoints: (
  sm: 576,
  xs: 768,
  lg: 1140,
  xl: 1440
)
