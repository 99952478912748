.blog-list {
  width: 100%;

  &__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-wrapper {
      border: 1px solid whitesmoke;
      width: 50px;
      height: 50px; } }

  &__title {
    max-width: 450px; }

  th {
    text-align: left; } }
