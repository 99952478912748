// File input
.app-file {
	&-btn {
		padding: 7px 15px;
		border: 1px dashed #a3a3a3;
		background: white;
		cursor: pointer;

		&:hover {
			background: rgba(#e7eef3, 0.3); } }

	&-preview {
		width: 30px;
		height: 30px;
		border: 1px solid #e7e7e7;

		&__wrapper {
			display: flex;
			align-items: center; }

		&__logo {
			width: 100%;
			height: 100%;
			object-fit: cover; }

		&__text {
			margin-left: 10px; }

		&__delete {
			display: flex;
			align-items: center;
			margin-left: 5px;
			font-size: 5px;
			background: none;
			cursor: pointer;

			&:hover {
				color: red; } } } }
