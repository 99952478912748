@import "../../assets/styles";

.app-form-datefield {
  font-size: 15px;
  height: 40px;
  width: 100%;
  border: 1px solid border-color('input');
  border-radius: 8px;
  transition: transition('input');
  outline: none;
  padding: 15px 10px;
  color: text-color('primary');

  &::placeholder {
    color: text-color('secondary1');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px; }

  &:focus {
    border-color: border-color('input-focused'); } }
