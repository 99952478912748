@import "../assets/styles";

.post {
  &-main {
    max-width: 1440px;
    margin: 0 auto; }

  &-logo {
    height: 258px;
    background-image: url('images/blog-sample.png');
    background-size: cover; }

  &-content {
    padding: 0 118px 151px 42px; }

  &-socials {
    margin-top: 55px;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px; }

  &-social {
    margin-left: 5px; }

  &-title {
    color: text-color('primary');
    font-size: 40px;
    letter-spacing: 0;
    line-height: 52px;
    margin-top: 10px;
    text-align: left; }

  &-text {
    color: text-color('secondary1');
    font-size: 18px;
    letter-spacing: 0;
    line-height: 35px;
    text-align: left;
    margin: 1em 0; }

  &-back-button {
    height: 46px;
    width: 210px;
    margin-top: 15px; } }
