@import "../assets/styles";

.admin-sidebar {
  background: theme(background-dark);
  width: 250px;
  color: white;
  height: 100%;

  &-hero {
    //height: 75px
    display: flex;
    align-items: center;
    font-size: 18px; }

  &-logo {
    height: 24px;

    &__wrapper {
      border-bottom: 1px solid rgba(255, 255, 255, 0.18);
      padding: 20px 0 30px 20px;
      width: 100%;
      text-align: left; } }

  &-divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
 }    //margin: 15px 0

  &-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 7px 0;
    background: transparent;
    color: white;
    width: 100%;

    &:focus,
    &:active {
      text-decoration: none; }

    &.active {
      background: #494861; }

    &__icon {
      margin: 0 15px; } } }
