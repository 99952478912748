.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  &__content {
    height: 300px;
    width: 325px; }

  &-form {
    &-field {
      margin: 20px 0 0; }

    &-submit {
      margin-top: 20px;
      width: 100%; } } }
