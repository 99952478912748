@import "../../assets/styles";

.app-form {
  &-label {
    font-size: 16px;

    &-wrapper {
      font-weight: 600;
      margin: 0 0 5px; }

    &__required {
      color: text-color('error');
      padding-left: 4px; } }

  &-sublabel {
    font-size: 13px;
    opacity: 0.65;
    font-weight: 300; } }
