@import "../../assets/styles";

.app-form-input {
  height: 46px;
  width: 100%;
  border: 1px solid border-color('input');
  border-radius: 8px;
  transition: transition('input');
  outline: none;
  padding: 15px 20px;
  font-size: 16px;
  color: text-color('primary');

  &::placeholder {
    color: rgba(text-color('secondary1'), 0.5);
    letter-spacing: 0;
    line-height: 16px; }

  &:focus {
    border-color: border-color('input-focused'); }

  &.flat {
    border-radius: 2px; }

  &.touched.invalid {
    color: text-color('error');
    border-color: text-color('error'); } }
