@import "../assets/styles";

.app-flat-button {
  height: 35px;
  border-radius: 5px;
  background-color: #fff;
  color: text-color('primary');
  transition: none;
  font-size: 14px;

  &.primary {
    background-color: theme('background-light'); }

  &.success {
    background-color: theme('success');
    color: text-color('secondary3'); }

  &.warning {
    background-color: theme('warning');
    color: text-color('secondary3'); }

  &.danger {
    background-color: theme('error'); }

  &:hover {
    background-color: #fff;

    &.primary,
    &.secondary,
    &.warning,
    &.danger {} }

  &:focus,
  &:active {
    box-shadow: 0 0 0 1px rgba(10, 11, 11, 0.88); } }
