@import "../../assets/styles";

.app-form {
  // Form error
  &-error {
    color: text-color('error');
    font-size: 13px;
    line-height: 1.5rem; } }




