.entry-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 15px;
  border-bottom: 1px solid #c8c8c8;
  background: rgb(250, 250, 250);
  height: 78px;

  &-title {
    font-size: 20px; }

  &-actions {
    > * {
      margin-left: 10px; } } }
