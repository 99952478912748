.blog-form {
  padding: 20px 15px 30px;

  &__input {
    border-radius: 2px; }

  &__field {
    &:not(:first-child) {
      margin-top: 20px; } }

  &__submit {
    margin-top: 15px;
    margin-left: 10px;
    display: block; }

  &__date-picker {
    border-radius: 2px; }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px; }

  &__action {
    margin-left: 10px !important; } }
